.flex {
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 1300px) {
  .image-row {
    margin-left: 400px;
    margin-right: 400px;
    height: 100%;
    padding-bottom: 30px;
  }
  .image {
    width: 820px;
    height: 600px;
    filter: contrast(0.7);
    object-fit: cover;
    margin-top: 55%;
  }
  .button-row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .right-button {
  }
  .left-button {
  }
  .modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    justify-content: center;
    align-items: center;
    z-index: 5;
  }

  .modal-content {
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    padding-left: 150px;
    padding-right: 150px;
    overflow: auto;
  }

  .modal-close {
    position: absolute;
    top: 20px;
    right: 50px;
    font-size: 55px;
    cursor: pointer;
    color: rgb(172, 172, 173);
  }
  .modal-title {
    position: absolute;
    top: 10px;
    font-size: 40px;
    cursor: pointer;
    color: rgb(0, 0, 0);
  }
  .modal-image {
    width: 100%;
    height: 750px;
    object-fit: cover;
  }
  .modal-button {
    color: rgb(172, 172, 173);
    font-size: 55px;
  }
}

@media only screen and (min-width: 1028px) and (max-width: 1299px) {
  .image-row {
    margin-left: 250px;
    margin-right: 250px;
    height: 100%;
    padding-bottom: 30px;
  }
  .image {
    width: 820px;
    height: 400px;
    filter: contrast(0.7);
    object-fit: cover;
    margin-top: 55%;
  }
  .button-row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  /* .right-button {
    position: absolute;
    transform: translateY(-50%);
    bottom: -1750px;
    right: 260px;
  }
  .left-button {
    position: absolute;
    transform: translateY(-50%);
    bottom: -1750px;
    left: 250px;
  } */
  .modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    justify-content: center;
    align-items: center;
    z-index: 5;
  }

  .modal-content {
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    padding-left: 150px;
    padding-right: 150px;
    overflow: auto;
  }

  .modal-close {
    position: absolute;
    top: 20px;
    right: 50px;
    font-size: 55px;
    cursor: pointer;
    color: rgb(172, 172, 173);
  }
  .modal-title {
    position: absolute;
    top: 10px;
    font-size: 40px;
    cursor: pointer;
    color: rgb(0, 0, 0);
  }
  .modal-image {
    width: 100%;
    height: 750px;
    object-fit: cover;
  }
  .modal-button {
    color: rgb(172, 172, 173);
    font-size: 55px;
  }
}

@media only screen and (min-width: 910px) and (max-width: 1027px) {
  .image-row {
    margin-left: 150px;
    margin-right: 150px;
    height: 100%;
    padding-bottom: 30px;
  }
  .image {
    width: 820px;
    height: 400px;
    filter: contrast(0.7);
    object-fit: cover;
    margin-top: 55%;
  }
  .button-row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  /* .right-button {
    position: absolute;
    transform: translateY(-50%);
    bottom: -1375px;
    right: 210px;
  }
  .left-button {
    position: absolute;
    transform: translateY(-50%);
    bottom: -1375px;
    left: 200px;
  } */
  .modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    justify-content: center;
    align-items: center;
    z-index: 5;
  }

  .modal-content {
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    padding-left: 150px;
    padding-right: 150px;
    overflow: auto;
  }

  .modal-close {
    position: absolute;
    top: 20px;
    right: 50px;
    font-size: 55px;
    cursor: pointer;
    color: rgb(172, 172, 173);
  }
  .modal-title {
    position: absolute;
    top: 10px;
    font-size: 40px;
    cursor: pointer;
    color: rgb(0, 0, 0);
  }
  .modal-image {
    width: 100%;
    height: 750px;
    object-fit: cover;
  }
  .modal-button {
    color: rgb(172, 172, 173);
    font-size: 55px;
  }
}

@media only screen and (min-width: 520px) and (max-width: 909px) {
  .image-row {
    margin-left: 100px;
    margin-right: 100px;
    height: 100%;
    padding-bottom: 30px;
  }
  .image {
    width: 820px;
    height: 300px;
    filter: contrast(0.7);
    object-fit: cover;
    margin-top: 55%;
  }
  .button-row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  /* .right-button {
    position: absolute;
    transform: translateY(-50%);
    bottom: -1375px;
    right: 210px;
  }
  .left-button {
    position: absolute;
    transform: translateY(-50%);
    bottom: -1375px;
    left: 200px;
  } */
  .modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    justify-content: center;
    align-items: center;
    z-index: 5;
  }

  .modal-content {
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    padding-left: 150px;
    padding-right: 150px;
    overflow: auto;
  }

  .modal-close {
    position: absolute;
    top: 20px;
    right: 50px;
    font-size: 55px;
    cursor: pointer;
    color: rgb(172, 172, 173);
  }
  .modal-title {
    position: absolute;
    top: 10px;
    font-size: 40px;
    cursor: pointer;
    color: rgb(0, 0, 0);
  }
  .modal-image {
    width: 100%;
    height: 750px;
    object-fit: cover;
  }
  .modal-button {
    color: rgb(172, 172, 173);
    font-size: 55px;
  }
}
@media only screen and (max-width: 519px) {
  .image-row {
    margin-left: 20px;
    margin-right: 20px;
    height: 100%;
    padding-bottom: 30px;
  }
  .image {
    width: 820px;
    height: 260px;
    filter: contrast(0.7);
    object-fit: cover;
    margin-top: 35%;
  }
  .button-row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  /* .right-button {
    position: absolute;
    transform: translateY(-50%);
    bottom: -1375px;
    right: 210px;
  }
  .left-button {
    position: absolute;
    transform: translateY(-50%);
    bottom: -1375px;
    left: 200px;
  } */
  .modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    justify-content: center;
    align-items: center;
    z-index: 5;
  }

  .modal-content {
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    padding-left: 10px;
    padding-right: 10px;
    overflow: auto;
  }

  .modal-close {
    position: absolute;
    top: 20px;
    right: 50px;
    font-size: 55px;
    cursor: pointer;
    color: rgb(172, 172, 173);
  }
  .modal-title {
    position: absolute;
    top: 10px;
    font-size: 40px;
    cursor: pointer;
    color: rgb(0, 0, 0);
  }
  .modal-image {
    width: 100%;
    height: 350px;
    object-fit: cover;
  }
  .modal-button {
    position: absolute;
    color: rgb(172, 172, 173);
    font-size: 55px;
  }
}
