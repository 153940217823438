.App {
  background-color: #ffffff;
}

@media only screen and (min-width: 1300px) {
  .room-header-detail-image {
    width: 100%;
    height: 400px;
    z-index: -1;
    object-fit: cover;
    padding-top: 150px;
    filter: contrast(0.8);
  }

  .room-detail-info {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
  }

  .room-detail-slider {
    display: flex;
    flex-direction: column;
    width: 1100px;
  }
  .room-detail-images-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
    overflow-x: auto;
    margin-left: 510px;
    margin-right: 510px;
    padding-left: 180px;
  }

  .room-detail-images-slider-image {
    width: 200px;
    height: 150px;
    object-fit: cover;
    margin-right: 10px;
    margin-left: 10px;
  }
  .room-detail-image {
    width: 720px;
    height: 530px;
    object-fit: cover;
  }
  .room-detail-modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    justify-content: center;
    align-items: center;
    z-index: 5;
  }

  .room-detail-modal-content {
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    padding-left: 150px;
    padding-right: 150px;
    overflow: auto;
  }

  .room-detail-modal-close {
    position: absolute;
    top: 20px;
    right: 50px;
    font-size: 55px;
    cursor: pointer;
    color: rgb(172, 172, 173);
  }
  .room-detail-modal-title {
    position: absolute;
    top: 10px;
    font-size: 40px;
    cursor: pointer;
    color: rgb(0, 0, 0);
  }
  .room-detail-modal-image {
    width: 100%;
    height: 750px;
    object-fit: cover;
  }
  .room-detail-modal-button {
    color: rgb(172, 172, 173);
    font-size: 55px;
  }
  .room-detail-header-row {
    margin-top: 85px;
    margin-bottom: 50px;
  }
  .room-detail-title {
    font-size: 28px;
    font-weight: bold;
    font-family: "Monaco", monospace;
    color: #e01616;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);
  }
  .room-detail-explanation {
    color: #666666;
    text-align: center;
    line-height: 40px;
    letter-spacing: normal;
    font-size: 18px;
    font-family: "Monaco", monospace;
    margin-top: 110px;
    margin-bottom: 50px;
    margin-left: 450px;
    margin-right: 450px;
  }
  .room-detail-features {
    display: flex;
    justify-content: center;
  }
  .room-detail-feature-row {
    margin-left: 90px;
    margin-right: 90px;
    margin-bottom: 100px;
  }
  .room-detail-feature-text {
    color: #666666;
    text-align: start;
    line-height: 25px;
    letter-spacing: normal;
    font-size: 18px;
    font-family: "Monaco", monospace;
  }
}

@media only screen and (min-width: 1028px) and (max-width: 1299px) {
  .room-header-detail-image {
    width: 100%;
    height: 400px;
    z-index: -1;
    object-fit: cover;
    padding-top: 150px;
    filter: contrast(0.8);
  }

  .room-detail-info {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
  }

  .room-detail-slider {
    display: flex;
    flex-direction: column;
    width: 900px;
  }
  .room-detail-images-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
    overflow-x: auto;
    margin-left: 260px;
    margin-right: 260px;
    padding-left: 180px;
  }

  .room-detail-images-slider-image {
    width: 200px;
    height: 150px;
    object-fit: cover;
    margin-right: 10px;
    margin-left: 10px;
  }
  .room-detail-image {
    width: 720px;
    height: 530px;
    object-fit: cover;
  }
  .room-detail-modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    justify-content: center;
    align-items: center;
    z-index: 5;
  }

  .room-detail-modal-content {
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    padding-left: 150px;
    padding-right: 150px;
    overflow: auto;
  }

  .room-detail-modal-close {
    position: absolute;
    top: 20px;
    right: 50px;
    font-size: 55px;
    cursor: pointer;
    color: rgb(172, 172, 173);
  }
  .room-detail-modal-title {
    position: absolute;
    top: 10px;
    font-size: 40px;
    cursor: pointer;
    color: rgb(0, 0, 0);
  }
  .room-detail-modal-image {
    width: 100%;
    height: 750px;
    object-fit: cover;
  }
  .room-detail-modal-button {
    color: rgb(172, 172, 173);
    font-size: 55px;
  }
  .room-detail-header-row {
    margin-top: 85px;
    margin-bottom: 50px;
  }
  .room-detail-title {
    font-size: 26px;
    font-weight: bold;
    text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);

    font-family: "Monaco", monospace;
    color: #e01616;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-left: 50px;
    margin-right: 50px;
  }
  .room-detail-explanation {
    color: #666666;
    text-align: center;
    line-height: 40px;
    letter-spacing: normal;
    font-size: 18px;
    font-family: "Monaco", monospace;
    margin-top: 110px;
    margin-bottom: 50px;
    margin-left: 120px;
    margin-right: 120px;
  }
  .room-detail-features {
    display: flex;
    justify-content: center;
  }
  .room-detail-feature-row {
    margin-left: 90px;
    margin-right: 90px;
    margin-bottom: 100px;
  }
  .room-detail-feature-text {
    color: #666666;
    text-align: start;
    line-height: 25px;
    letter-spacing: normal;
    font-size: 18px;
    font-family: "Monaco", monospace;
  }
}

@media only screen and (min-width: 910px) and (max-width: 1027px) {
  .room-header-detail-image {
    width: 100%;
    height: 350px;
    z-index: -1;
    object-fit: cover;
    padding-top: 120px;
    filter: contrast(0.8);
  }

  .room-detail-info {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
  }

  .room-detail-slider {
    display: flex;
    flex-direction: column;
    width: 800px;
  }
  .room-detail-images-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
    overflow-x: auto;
    margin-left: 160px;
    margin-right: 160px;
    padding-left: 180px;
  }

  .room-detail-images-slider-image {
    width: 200px;
    height: 150px;
    object-fit: cover;
    margin-right: 10px;
    margin-left: 10px;
  }
  .room-detail-image {
    width: 520px;
    height: 430px;
    object-fit: cover;
  }
  .room-detail-modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    justify-content: center;
    align-items: center;
    z-index: 5;
  }

  .room-detail-modal-content {
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    padding-left: 150px;
    padding-right: 150px;
    overflow: auto;
  }

  .room-detail-modal-close {
    position: absolute;
    top: 20px;
    right: 50px;
    font-size: 55px;
    cursor: pointer;
    color: rgb(172, 172, 173);
  }
  .room-detail-modal-title {
    position: absolute;
    top: 10px;
    font-size: 40px;
    cursor: pointer;
    color: rgb(0, 0, 0);
  }
  .room-detail-modal-image {
    width: 100%;
    height: 750px;
    object-fit: cover;
  }
  .room-detail-modal-button {
    color: rgb(172, 172, 173);
    font-size: 55px;
  }
  .room-detail-header-row {
    margin-top: 85px;
    margin-bottom: 50px;
  }

  .room-detail-title {
    font-size: 27px;
    font-weight: bold;
    font-family: "Monaco", monospace;
    color: #e01616;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-left: 50px;
    margin-right: 50px;
    text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);
  }
  .room-detail-explanation {
    color: #666666;
    text-align: center;
    line-height: 40px;
    letter-spacing: normal;
    font-size: 18px;
    font-family: "Monaco", monospace;
    margin-top: 110px;
    margin-bottom: 50px;
    margin-left: 90px;
    margin-right: 90px;
  }
  .room-detail-features {
    display: flex;
    justify-content: center;
  }
  .room-detail-feature-row {
    margin-left: 90px;
    margin-right: 90px;
    margin-bottom: 100px;
  }
  .room-detail-feature-text {
    color: #666666;
    text-align: start;
    line-height: 25px;
    letter-spacing: normal;
    font-size: 18px;
    font-family: "Monaco", monospace;
  }
}

@media only screen and (min-width: 520px) and (max-width: 909px) {
  .room-header-detail-image {
    width: 100%;
    height: 290px;
    z-index: -1;
    object-fit: cover;
    padding-top: 120px;
    filter: contrast(0.8);
  }

  .room-detail-info {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
  }

  .room-detail-slider {
    display: flex;
    flex-direction: column;
    width: 650px;
  }
  .room-detail-images-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
    overflow-x: auto;
    margin-left: 130px;
    margin-right: 130px;
    padding-left: 180px;
  }

  .room-detail-images-slider-image {
    width: 200px;
    height: 150px;
    object-fit: cover;
    margin-right: 10px;
    margin-left: 10px;
  }
  .room-detail-image {
    width: 720px;
    height: 410px;
    object-fit: cover;
  }
  .room-detail-modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    justify-content: center;
    align-items: center;
    z-index: 5;
  }

  .room-detail-modal-content {
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    padding-left: 90px;
    padding-right: 90px;
    overflow: auto;
  }

  .room-detail-modal-close {
    position: absolute;
    top: 20px;
    right: 50px;
    font-size: 55px;
    cursor: pointer;
    color: rgb(172, 172, 173);
  }
  .room-detail-modal-title {
    position: absolute;
    top: 10px;
    font-size: 40px;
    cursor: pointer;
    color: rgb(0, 0, 0);
  }
  .room-detail-modal-image {
    width: 100%;
    height: 750px;
    object-fit: cover;
  }
  .room-detail-modal-button {
    color: rgb(172, 172, 173);
    font-size: 55px;
  }
  .room-detail-header-row {
    margin-top: 85px;
    margin-bottom: 50px;
  }
  .room-detail-title {
    font-size: 26px;
    font-family: "Monaco", monospace;
    color: #e01616;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-left: 70px;
    margin-right: 70px;
    text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);
  }
  .room-detail-explanation {
    color: #666666;
    text-align: center;
    line-height: 40px;
    letter-spacing: normal;
    font-size: 18px;
    font-family: "Monaco", monospace;
    margin-top: 110px;
    margin-bottom: 50px;
    margin-left: 90px;
    margin-right: 90px;
  }
  .room-detail-features {
    display: flex;
    justify-content: center;
  }
  .room-detail-feature-row {
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 100px;
  }
  .room-detail-feature-text {
    color: #666666;
    text-align: start;
    line-height: 25px;
    letter-spacing: normal;
    font-size: 18px;
    font-family: "Monaco", monospace;
  }
}
@media only screen and (max-width: 519px) {
  .room-header-detail-image {
    width: 100%;
    height: 250px;
    z-index: -1;
    object-fit: cover;
    padding-top: 150px;
    filter: contrast(0.8);
  }
  .room-header-min-screen {
    z-index: -1;
    object-fit: cover;
    padding-top: 50px;
  }
  .room-detail-info {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
  }

  .room-detail-slider {
    display: flex;
    flex-direction: column;
    width: 450px;
  }
  .room-detail-images-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
    overflow-x: auto;
    padding-left: 180px;
  }

  .room-detail-images-slider-image {
    width: 100px;
    height: 70px;
    object-fit: cover;
    margin-right: 10px;
    margin-left: 10px;
  }
  .room-detail-image {
    width: 520px;
    height: 300px;
    object-fit: cover;
  }
  .room-detail-modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    justify-content: center;
    align-items: center;
    z-index: 5;
  }

  .room-detail-modal-content {
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    overflow: auto;
  }

  .room-detail-modal-close {
    position: absolute;
    top: 20px;
    right: 50px;
    font-size: 55px;
    cursor: pointer;
    color: rgb(172, 172, 173);
  }
  .room-detail-modal-title {
    position: absolute;
    top: 10px;
    font-size: 40px;
    cursor: pointer;
    color: rgb(0, 0, 0);
  }
  .room-detail-modal-image {
    width: 100%;
    height: 450px;
    object-fit: contain;
  }
  .room-detail-modal-button {
    color: rgb(172, 172, 173);
    font-size: 55px;
  }
  .room-detail-header-row {
    margin-top: 85px;
    margin-bottom: 50px;
  }
  .room-detail-title {
    font-size: 20px;
    font-weight: 800;
    font-family: "Monaco", monospace;
    color: #e01616;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-left: 15px;
    margin-right: 15px;
    text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);
  }
  .room-detail-explanation {
    color: #666666;
    text-align: center;
    line-height: 23px;
    letter-spacing: normal;
    font-size: 14px;
    font-family: "Monaco", monospace;
    margin-top: 110px;
    margin-bottom: 50px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .room-detail-features {
    margin-bottom: 100px;
  }
  .room-detail-feature-row {
    margin-left: 50px;
    margin-right: 50px;
  }
  .room-detail-feature-text {
    color: #666666;
    text-align: start;
    line-height: 25px;
    letter-spacing: normal;
    font-size: 16px;
    font-family: "Monaco", monospace;
  }
}
