@media only screen and (min-width: 1300px) {
  .rooms-container {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin-bottom: 400px;
  }

  .rooms-image-left {
    width: 320px;
    height: 320px;
    margin-left: 100px;
    filter: contrast(0.7);
  }
  .rooms-image-right {
    width: 320px;
    height: 320px;
    position: absolute;
    margin-top: 260px;
    filter: contrast(0.7);
  }
  .rooms-left {
    width: 320px;
    height: 320px;
    margin-left: 100px;
    position: absolute;
    margin-top: 260px;
    filter: contrast(0.7);
    z-index: 1;
  }
  .rooms-right {
    width: 320px;
    height: 320px;
    filter: contrast(0.7);
  }
  .rooms-image-container {
    display: flex;
  }
  .rooms-title-row {
    margin-left: 80px;
  }
  .rooms-title-row-left {
    margin-right: 80px;
  }
  .rooms-title {
    font-size: 28px;
    font-family: "Monaco", monospace;
    color: #e01616;
  }
  .rooms-button {
    font-size: 14px;
    font-family: "Monaco", monospace;
    font-weight: 500;
    letter-spacing: 0;
    text-align: start;
    padding: 8px;
    width: 80px;
    background-color: #e01616;
    color: #ffffff;
  }

  .rooms-expectation {
    font-size: 14px;
    font-family: "Monaco", monospace;
    letter-spacing: 0;
    text-align: start;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1028px) and (max-width: 1299px) {
  .rooms-container {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin-bottom: 350px;
  }

  .rooms-image-left {
    width: 290px;
    height: 290px;
    margin-left: 80px;
    filter: contrast(0.7);
  }
  .rooms-image-right {
    width: 290px;
    height: 290px;
    position: absolute;
    margin-top: 170px;
    filter: contrast(0.7);
  }
  .rooms-left {
    width: 290px;
    height: 290px;
    margin-left: 80px;
    position: absolute;
    margin-top: 170px;
    filter: contrast(0.7);
    z-index: 1;
  }
  .rooms-right {
    width: 290px;
    height: 290px;
    filter: contrast(0.7);
  }
  .rooms-image-container {
    display: flex;
  }
  .rooms-title-row {
    margin-left: 70px;
  }
  .rooms-title-row-left {
    margin-right: 70px;
  }
  .rooms-title {
    font-size: 27px;
    font-family: "Monaco", monospace;
    color: #e01616;
  }
  .rooms-button {
    font-size: 14px;
    font-family: "Monaco", monospace;
    font-weight: 500;
    letter-spacing: 0;
    text-align: start;
    padding: 8px;
    width: 80px;
    background-color: #e01616;
    color: #ffffff;
  }

  .rooms-expectation {
    font-size: 14px;
    font-family: "Monaco", monospace;
    letter-spacing: 0;
    text-align: start;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 910px) and (max-width: 1027px) {
  .rooms-container {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin-bottom: 350px;
  }

  .rooms-image-left {
    width: 290px;
    height: 290px;
    margin-left: 80px;
    filter: contrast(0.7);
  }
  .rooms-image-right {
    width: 290px;
    height: 290px;
    position: absolute;
    margin-top: 170px;
    filter: contrast(0.7);
  }
  .rooms-left {
    width: 290px;
    height: 290px;
    margin-left: 80px;
    position: absolute;
    margin-top: 170px;
    filter: contrast(0.7);
    z-index: 1;
  }
  .rooms-right {
    width: 290px;
    height: 290px;
    filter: contrast(0.7);
  }
  .rooms-image-container {
    display: flex;
  }
  .rooms-title-row {
    margin-left: 70px;
  }
  .rooms-title-row-left {
    margin-right: 70px;
  }
  .rooms-title {
    font-size: 27px;
    font-family: "Monaco", monospace;
    color: #e01616;
  }
  .rooms-button {
    font-size: 14px;
    font-family: "Monaco", monospace;
    font-weight: 500;
    letter-spacing: 0;
    text-align: start;
    padding: 8px;
    width: 80px;
    background-color: #e01616;
    color: #ffffff;
  }

  .rooms-expectation {
    font-size: 14px;
    font-family: "Monaco", monospace;
    letter-spacing: 0;
    text-align: start;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 520px) and (max-width: 909px) {
  .rooms-container {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin-bottom: 220px;
  }

  .rooms-image-left {
    width: 200px;
    height: 200px;
    margin-left: 70px;
    filter: contrast(0.7);
  }
  .rooms-image-right {
    width: 200px;
    height: 200px;
    position: absolute;
    margin-top: 140px;
    filter: contrast(0.7);
  }
  .rooms-left {
    width: 200px;
    height: 200px;
    margin-left: 70px;
    position: absolute;
    margin-top: 140px;
    filter: contrast(0.7);
    z-index: 1;
  }
  .rooms-right {
    width: 200px;
    height: 200px;
    filter: contrast(0.7);
  }
  .rooms-image-container {
    display: flex;
  }
  .rooms-title-row {
    margin-left: 50px;
  }
  .rooms-title-row-left {
    margin-right: 50px;
  }
  .rooms-title {
    font-size: 24px;
    font-family: "Monaco", monospace;
    color: #e01616;
  }
  .rooms-button {
    font-size: 12px;
    font-family: "Monaco", monospace;
    font-weight: 300;
    letter-spacing: 0;
    text-align: start;
    padding: 8px;
    width: 80px;
    background-color: #e01616;
    color: #ffffff;
  }

  .rooms-expectation {
    font-size: 14px;
    font-family: "Monaco", monospace;
    letter-spacing: 0;
    text-align: start;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 519px) {
  .rooms-container {
    /* align-items: center; */
    margin-bottom: 100px;
  }

  .rooms-image-left {
    width: 200px;
    height: 200px;
    filter: contrast(0.7);
  }
  .rooms-image-right {
    width: 200px;
    height: 200px;
    filter: contrast(0.7);
  }
  .rooms-left {
    width: 90%;
    height: 250px;
    filter: contrast(0.7);
    object-fit: cover;
    margin-left: 20px;
    margin-right: 20px;
  }
  .rooms-right {
    width: 100%;
    height: 250px;
    filter: contrast(0.7);
    object-fit: cover;
  }
  .rooms-image-container {
    display: flex;
  }

  .rooms-title-row-left {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .rooms-title {
    font-size: 22px;
    font-family: "Monaco", monospace;
    color: #e01616;
  }
  .buttons-row-flex {
    display: flex;
    justify-content: center;
  }
  .rooms-button {
    font-size: 12px;
    font-family: "Monaco", monospace;
    font-weight: 500;
    letter-spacing: 0;
    padding: 8px;
    width: 80px;
    background-color: #e01616;
    color: #ffffff;
  }

  .rooms-expectation {
    font-size: 14px;
    font-family: "Monaco", monospace;
    letter-spacing: 0;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }
}
