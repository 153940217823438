.App {
  background-color: #ffffff;
}

@media only screen and (min-width: 1300px) {
  .room-image {
    width: 100%;
    height: 400px;
    z-index: -1;
    object-fit: cover;
    padding-top: 150px;
    filter: contrast(0.8);
  }

  .home-background {
    background-image: url("../../assets/background.png");
    background-size: contain;
    background-repeat: repeat-y;
  }

  .room-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 100px;
  }

  .room-header-row {
    margin-top: 100px;
    margin-bottom: 70px;
  }
  .room-title {
    font-size: 28px;
    font-family: "Monaco", monospace;
    color: #e01616;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;
    text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);
  }

  .room-explanation {
    color: #232323;
    text-align: center;
    margin-left: 420px;
    margin-right: 420px;
    line-height: 25px;
    letter-spacing: normal;
    font-size: 14px;
    font-family: "Monaco", monospace;
    margin-top: 20px;
  }

  .room-card-container {
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 150px;
    margin-bottom: 150px;
  }

  .room-card-image {
    width: 420px;
    height: 420px;
    z-index: -1;
  }

  .rooms-card-title-row {
    position: absolute;
    width: 350px;
    background-color: #ffffff;
    padding: 10px;
    margin-top: -100px;
    z-index: 1;
    box-shadow: 4px 4px 4px 4px #bcbcbc;
  }

  .rooms-card-title {
    font-size: 22px;
    font-family: "Monaco", monospace;
    color: #e01616;
  }
  .room-card-button {
    font-size: 14px;
    font-family: "Monaco", monospace;
    font-weight: 500;
    letter-spacing: 0;
    text-align: start;
    padding: 8px;
    width: 80px;
    background-color: #e01616;
    color: #ffffff;
  }
  .icon-container {
    display: flex;
    justify-content: space-around;
    margin-left: 50px;
    margin-right: 50px;
  }

  .room-icon-row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .icon-title {
    font-size: 22px;
    font-family: "Monaco", monospace;
    color: #e01616;
    text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);
  }
}

@media only screen and (min-width: 1028px) and (max-width: 1299px) {
  .room-image {
    width: 100%;
    height: 400px;
    z-index: -1;
    object-fit: cover;
    padding-top: 150px;
    filter: contrast(0.8);
  }

  .home-background {
    background-image: url("../../assets/background.png");
    background-size: contain;
    background-repeat: repeat-y;
  }

  .room-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 100px;
  }

  .room-header-row {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .room-title {
    font-size: 28px;
    font-family: "Monaco", monospace;
    color: #e01616;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;
    text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);
  }

  .room-explanation {
    color: #232323;
    text-align: center;
    margin-left: 220px;
    margin-right: 220px;
    line-height: 25px;
    letter-spacing: normal;
    font-size: 14px;
    font-family: "Monaco", monospace;
    margin-top: 20px;
  }

  .room-card-container {
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 150px;
    margin-bottom: 150px;
  }

  .room-card-image {
    width: 420px;
    height: 420px;
    z-index: -1;
  }

  .rooms-card-title-row {
    position: absolute;
    width: 350px;
    background-color: #ffffff;
    padding: 10px;
    margin-top: -100px;
    z-index: 1;
    box-shadow: 4px 4px 4px 4px #bcbcbc;
  }

  .rooms-card-title {
    font-size: 22px;
    font-family: "Monaco", monospace;
    color: #e01616;
  }
  .room-card-button {
    font-size: 14px;
    font-family: "Monaco", monospace;
    font-weight: 500;
    letter-spacing: 0;
    text-align: start;
    padding: 8px;
    width: 80px;
    background-color: #e01616;
    color: #ffffff;
  }
  .icon-container {
    display: flex;
    justify-content: space-around;
    margin-left: 50px;
    margin-right: 50px;
  }

  .room-icon-row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .icon-title {
    font-size: 22px;
    font-family: "Monaco", monospace;
    color: #e01616;
    text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);
  }
}
@media only screen and (min-width: 910px) and (max-width: 1027px) {
  .room-image {
    width: 100%;
    height: 400px;
    z-index: -1;
    object-fit: cover;
    padding-top: 150px;
    filter: contrast(0.8);
  }

  .home-background {
    background-image: url("../../assets/background.png");
    background-size: contain;
    background-repeat: repeat-y;
  }

  .room-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 100px;
  }

  .room-header-row {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .room-title {
    font-size: 28px;
    font-family: "Monaco", monospace;
    color: #e01616;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;
    text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);
  }

  .room-explanation {
    color: #232323;
    text-align: center;
    margin-left: 170px;
    margin-right: 170px;
    line-height: 25px;
    letter-spacing: normal;
    font-size: 14px;
    font-family: "Monaco", monospace;
    margin-top: 20px;
  }

  .room-card-container {
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 150px;
    margin-bottom: 150px;
  }

  .room-card-image {
    width: 420px;
    height: 420px;
    z-index: -1;
  }

  .rooms-card-title-row {
    position: absolute;
    width: 350px;
    background-color: #ffffff;
    padding: 10px;
    margin-top: -100px;
    z-index: 1;
    box-shadow: 4px 4px 4px 4px #bcbcbc;
  }

  .rooms-card-title {
    font-size: 22px;
    font-family: "Monaco", monospace;
    color: #e01616;
  }
  .room-card-button {
    font-size: 14px;
    font-family: "Monaco", monospace;
    font-weight: 500;
    letter-spacing: 0;
    text-align: start;
    padding: 8px;
    width: 80px;
    background-color: #e01616;
    color: #ffffff;
  }
  .icon-container {
    display: flex;
    justify-content: space-around;
    margin-left: 50px;
    margin-right: 50px;
  }

  .room-icon-row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .icon-title {
    font-size: 22px;
    font-family: "Monaco", monospace;
    color: #e01616;
    text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);
  }
}
@media only screen and (min-width: 520px) and (max-width: 909px) {
  .room-image {
    width: 100%;
    height: 400px;
    z-index: -1;
    object-fit: cover;
    padding-top: 150px;
    filter: contrast(0.8);
  }

  .home-background {
    background-image: url("../../assets/background.png");
    background-size: contain;
    background-repeat: repeat-y;
  }

  .room-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 100px;
  }

  .room-header-row {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .room-title {
    font-size: 28px;
    font-family: "Monaco", monospace;
    color: #e01616;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;
    text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);
  }

  .room-explanation {
    color: #232323;
    text-align: center;
    margin-left: 140px;
    margin-right: 140px;
    line-height: 25px;
    letter-spacing: normal;
    font-size: 14px;
    font-family: "Monaco", monospace;
    margin-top: 20px;
  }

  .room-card-container {
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 150px;
    margin-bottom: 150px;
  }

  .room-card-image {
    width: 420px;
    height: 420px;
    z-index: -1;
  }

  .rooms-card-title-row {
    position: absolute;
    width: 350px;
    background-color: #ffffff;
    padding: 10px;
    margin-top: -100px;
    z-index: 1;
    box-shadow: 4px 4px 4px 4px #bcbcbc;
  }

  .rooms-card-title {
    font-size: 22px;
    font-family: "Monaco", monospace;
    color: #e01616;
  }
  .room-card-button {
    font-size: 14px;
    font-family: "Monaco", monospace;
    font-weight: 500;
    letter-spacing: 0;
    text-align: start;
    padding: 8px;
    width: 80px;
    background-color: #e01616;
    color: #ffffff;
  }
  .icon-container {
    display: flex;
    justify-content: space-around;
    margin-left: 50px;
    margin-right: 50px;
  }

  .room-icon-row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .icon-title {
    font-size: 22px;
    font-family: "Monaco", monospace;
    color: #e01616;
    text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);
  }
}
@media only screen and (max-width: 519px) {
  .room-image {
    width: 100%;
    height: 400px;
    z-index: -1;
    object-fit: cover;
    padding-top: 150px;
    filter: contrast(0.8);
  }

  .home-background {
    background-image: url("../../assets/background.png");
    background-size: contain;
    background-repeat: repeat-y;
  }

  .room-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 100px;
  }

  .room-header-row {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .room-title {
    font-size: 28px;
    font-family: "Monaco", monospace;
    color: #e01616;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;
    text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);
  }

  .room-explanation {
    color: #232323;
    text-align: center;
    margin-left: 30px;
    margin-right: 30px;
    line-height: 25px;
    letter-spacing: normal;
    font-size: 14px;
    font-family: "Monaco", monospace;
    margin-top: 20px;
  }

  .room-card-container {
    margin-top: 150px;
    margin-bottom: 150px;
  }

  .room-card-image {
    width: 370px;
    height: 370px;
    z-index: -1;
  }

  .rooms-card-title-row {
    position: absolute;
    width: 290px;
    background-color: #ffffff;
    padding: 10px;
    margin-top: -100px;
    z-index: 1;
    box-shadow: 4px 4px 4px 4px #bcbcbc;
  }

  .rooms-card-title {
    font-size: 20px;
    font-family: "Monaco", monospace;
    color: #e01616;
  }
  .room-card-button {
    font-size: 14px;
    font-family: "Monaco", monospace;
    font-weight: 400;
    letter-spacing: 0;
    text-align: start;
    padding: 6px;
    width: 70px;
    background-color: #e01616;
    color: #ffffff;
  }
  .icon-container {
    display: flex;
    justify-content: space-around;
    margin-left: 50px;
    margin-right: 50px;
  }

  .room-icon-row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .icon-title {
    font-size: 17px;
    font-family: "Monaco", monospace;
    color: #e01616;
    text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);
  }
}
