.App {
  background-color: #ffffff;
}

.success-message {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4CAF50;
  color: white;
  padding: 15px 30px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  z-index: 1000;
  animation: slideDown 0.5s ease-out;
  text-align: center;
}

@keyframes slideDown {
  from {
    top: -100px;
    opacity: 0;
  }
  to {
    top: 20px;
    opacity: 1;
  }
}

.success-message p {
  margin: 5px 0;
  font-size: 16px;
}

@media only screen and (min-width: 1300px) {
  form {
    display: flex;
    flex-direction: column;
    width: "100%";
    margin: auto;
    align-items: center;
  }

  label,
  input,
  textarea {
    margin: 10px 0;
    font-family: "Monaco", monospace;
  }

  .contact-form-row {
    display: flex;
  }

  .contact-size-box {
    width: 50px;
  }
  .contact-form {
    display: flex;
    flex-direction: column;
  }

  .contact-input {
    width: 500px;
    height: 30px;
    font-size: 16px;
    font-family: "Monaco", monospace;
    padding: 5px;
  }
  .contact-massage-input {
    width: 1070px;
    height: 80px;
    font-size: 16px;
    font-family: "Monaco", monospace;
    padding: 5px;
  }
  button {
    margin-top: 20px;
    padding: 10px;
    background-color: #e01616;
    color: white;
    border: none;
    cursor: pointer;
    width: 150px;
    height: 40px;
    margin-bottom: 200px;
  }

  button:hover {
    background-color: #e01616;
  }
  .contact-explanation-row {
    padding-top: 160px;
    padding-bottom: 60px;
  }
  .contact-title {
    font-size: 28px;
    font-family: "Monaco", monospace;
    color: #e01616;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);
  }
}

@media only screen and (min-width: 1028px) and (max-width: 1299px) {
  form {
    display: flex;
    flex-direction: column;
    width: "100%";
    margin: auto;
    align-items: center;
  }

  label,
  input,
  textarea {
    margin: 10px 0;
    font-family: "Monaco", monospace;
  }

  .contact-form-row {
    display: flex;
  }

  .contact-size-box {
    width: 45px;
  }
  .contact-form {
    display: flex;
    flex-direction: column;
  }

  .contact-input {
    width: 450px;
    height: 30px;
    font-size: 16px;
    font-family: "Monaco", monospace;
    padding: 5px;
  }
  .contact-massage-input {
    width: 965px;
    height: 80px;
    font-size: 16px;
    font-family: "Monaco", monospace;
    padding: 5px;
  }
  button {
    margin-top: 20px;
    padding: 10px;
    background-color: #e01616;
    color: white;
    border: none;
    cursor: pointer;
    width: 150px;
    height: 40px;
    margin-bottom: 200px;
  }

  button:hover {
    background-color: #e01616;
  }
  .contact-explanation-row {
    padding-top: 160px;
    padding-bottom: 60px;
  }
  .contact-title {
    font-size: 28px;
    font-family: "Monaco", monospace;
    color: #e01616;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);
  }
}

@media only screen and (min-width: 910px) and (max-width: 1027px) {
  form {
    display: flex;
    flex-direction: column;
    width: "100%";
    margin: auto;
    align-items: center;
  }

  label,
  input,
  textarea {
    margin: 10px 0;
    font-family: "Monaco", monospace;
  }

  .contact-form-row {
    display: flex;
  }

  .contact-size-box {
    width: 30px;
  }
  .contact-form {
    display: flex;
    flex-direction: column;
  }

  .contact-input {
    width: 300px;
    height: 30px;
    font-size: 16px;
    padding: 5px;
    font-family: "Monaco", monospace;
  }
  .contact-massage-input {
    width: 650px;
    height: 80px;
    font-size: 16px;
    padding: 5px;
    font-family: "Monaco", monospace;
  }
  button {
    margin-top: 20px;
    padding: 10px;
    background-color: #e01616;
    color: white;
    border: none;
    cursor: pointer;
    width: 150px;
    height: 40px;
    margin-bottom: 200px;
  }

  button:hover {
    background-color: #e01616;
  }
  .contact-explanation-row {
    padding-top: 160px;
    padding-bottom: 60px;
  }
  .contact-title {
    font-size: 28px;
    font-family: "Monaco", monospace;
    color: #e01616;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);
  }
}

@media only screen and (min-width: 520px) and (max-width: 909px) {
  form {
    display: flex;
    flex-direction: column;
    width: "100%";
    margin: auto;
    align-items: center;
  }

  label,
  input,
  textarea {
    margin: 10px 0;
    font-family: "Monaco", monospace;
  }

  .contact-form-row {
    display: flex;
  }

  .contact-size-box {
    width: 20px;
  }
  .contact-form {
    display: flex;
    flex-direction: column;
  }

  .contact-input {
    width: 270px;
    height: 30px;
    font-size: 16px;
    font-family: "Monaco", monospace;
    padding: 5px;
  }
  .contact-massage-input {
    width: 580px;
    height: 80px;
    font-size: 16px;
    font-family: "Monaco", monospace;
    padding: 5px;
  }
  button {
    margin-top: 20px;
    padding: 10px;
    background-color: #e01616;
    color: white;
    border: none;
    cursor: pointer;
    width: 150px;
    height: 40px;
    margin-bottom: 200px;
  }

  button:hover {
    background-color: #e01616;
  }
  .contact-explanation-row {
    padding-top: 160px;
    padding-bottom: 60px;
  }
  .contact-title {
    font-size: 24px;
    font-family: "Monaco", monospace;
    color: #e01616;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);
  }
}
@media only screen and (max-width: 519px) {
  form {
    display: flex;
    flex-direction: column;
    width: "100%";
    margin-left: 20px;
    margin-right: 20px;
  }

  label,
  input,
  textarea {
    margin: 10px 0;
    font-family: "Monaco", monospace;
  }

  .contact-form-row {
    display: flex;
  }

  .contact-size-box {
    width: 50px;
  }
  .contact-form {
    display: flex;
    flex-direction: column;
  }

  .contact-input {
    height: 30px;
    font-size: 16px;
    font-family: "Monaco", monospace;
    padding: 5px;
  }
  .contact-massage-input {
    height: 80px;
    font-size: 16px;
    font-family: "Monaco", monospace;
    padding: 5px;
  }
  button {
    margin-top: 20px;
    padding: 10px;
    background-color: #e01616;
    color: white;
    border: none;
    cursor: pointer;
    width: 150px;
    height: 40px;
    margin-bottom: 100px;
    align-self: center;
  }

  button:hover {
    background-color: #e01616;
  }
  .contact-explanation-row {
    padding-top: 50px;
    padding-bottom: 10px;
  }
  .contact-title {
    font-size: 22px;
    font-family: "Monaco", monospace;
    color: #e01616;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 50px;
    text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);
  }
}
