.toggle-container {
  display: flex;
  position: fixed;
  width: 100%;
  background-color: #333333;
  z-index: 4;
}

.toggle-row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex: 1;
}

.toggle-name-row {
  display: flex;
}
.toggle-hotel-name {
  font-size: 47px;
  margin-right: 10px;
  margin-top: 20px;
  font-family: "Monaco", monospace;
  color: #ffffff;
}
.toggle-hotel {
  margin-top: 50px;
  font-family: "Monaco", monospace;
  color: #ffffff;
}

.toggle-icon-row {
  display: flex;
  justify-content: flex-end;
  margin-right: 15%;
  margin-top: 10%;
  margin-bottom: 15%;
}

.toggle-title-row {
  display: flex;
  justify-content: center;
}
.toggle-title {
  color: rgb(0, 0, 0);
  font-family: "Monaco", monospace;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: start;
}
.toggle-title-selected {
  /* color: #b29455; */
  color: #e01616;
  font-family: "Monaco", monospace;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: start;
}

.social-icon-row {
  display: flex;
  justify-content: center;
  margin-top: 100%;
}

.timya-image {
  width: 40%;
  height: 30%;
}

.icon {
  font-size: 35px;
}