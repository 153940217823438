.header-container {
  background-color: #ffffff;
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  /* border-bottom: 1px solid rgb(210, 210, 210); */
  height: 140px;
  z-index: 4;
}

.header-title-container {
  display: flex;
}
.header-title-row {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
  padding-top: 20px;
}
.name-row {
  display: flex;
}
.hotel-name {
  font-size: 47px;
  margin-right: 10px;
  margin-top: 20px;
  font-family: "Monaco", monospace;
  /* font-family: Arial, sans-serif;

  font-family: "Times New Roman", serif;

  font-family: Verdana, Geneva, sans-serif;

  font-family: "Courier New", Courier, monospace;

  font-family: Georgia, serif;

  font-family: "Comic Sans MS", cursive;

  font-family: Impact, Charcoal, sans-serif;

  font-family: Tahoma, Geneva, sans-serif;


  font-family: "Trebuchet MS", sans-serif;


  font-family: Calibri, sans-serif; */
}
.hotel {
  margin-top: 50px;
  font-family: "Monaco", monospace;
}
.header-title {
  color: rgb(0, 0, 0);
  font-family: "Monaco", monospace;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: start;
}
.header-title-selected {
  /* color: #b29455; */
  color: #e01616;
  font-family: "Monaco", monospace;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: start;
}

.timya-image {
  width: 60%;
  height: 40%;
}

.icon-row {
  display: flex;
  margin-left: 25px;
}
.icon {
  font-size: 40px;
}
