.about-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-size: 14px;
  font-family: "Monaco", monospace;
  margin-bottom: 100px;
}

.about-section {
  margin-bottom: 3rem;
  margin-top: 8rem;
}

.about-section h1 {
  color: #e01616;
  text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);
  margin-bottom: 1.5rem;
  font-family: "Monaco", monospace;
}

.about-section p {
  line-height: 1.6;
  color: #666;
  margin-bottom: 1rem;
  font-size: 14px;
  font-family: "Monaco", monospace;
}

.location-section h2 {
  color: #e01616;
  text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);
  margin-bottom: 2rem;
  font-family: "Monaco", monospace;
}

.location-section h3 {
  color: #e01616;
  text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);
  font-family: "Monaco", monospace;
}

.location-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.location-item {
  background-color: #f8f8f8;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.location-item h3 {
  color: #e01616;
  text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);
  margin-bottom: 1rem;
  font-family: "Monaco", monospace;
}

.location-item ul {
  list-style: none;
  padding: 0;
}

.location-item li {
  padding: 0.5rem 0;
  color: #666;
  border-bottom: 1px solid #eee;
  font-size: 14px;
  font-family: "Monaco", monospace;
}

.location-item li:last-child {
  border-bottom: none;
}

.about-image {
  width: 100%;
  height: 180px;  
}

@media (max-width: 768px) {
  .about-container {
    padding: 1rem;
  }
  
  .location-grid {
    grid-template-columns: 1fr;
  }
} 

