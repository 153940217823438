@media only screen and (min-width: 1028px) {
  .footer-container {
    background-color: #000;
    color: #ffffff;
    padding: 20px;
    text-align: center;
  }
  .footer-row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hotel-name {
    font-size: 50px;
    margin-right: 10px;
    margin-top: 25px;
    font-family: "Monaco", monospace;
  }
  .hotel {
    font-weight: 200px;
    margin-top: 57px;
    font-family: "Monaco", monospace;
  }
  .text-row {
    margin-right: 5%;
    max-width: 14%;
  }
  .text {
    font-size: 13px;
    font-family: "Monaco", monospace;
  }
  .left-row {
    display: flex;
    margin-right: 5%;
  }

  .since-row {
    margin-top: 50px;
    font-family: "Monaco", monospace;
    font-size: 12px;
  }
}

@media only screen and (min-width: 840px) and (max-width: 1027px) {
  .footer-container {
    background-color: #000;
    color: #ffffff;
    padding: 20px;
    text-align: center;
  }
  .footer-row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hotel-name {
    font-size: 50px;
    margin-right: 10px;
    margin-top: 25px;
    font-family: "Monaco", monospace;
  }
  .hotel {
    font-weight: 200px;
    margin-top: 57px;
    font-family: "Monaco", monospace;
  }
  .text-row {
    margin-right: 5%;
    max-width: 20%;
  }
  .text {
    font-size: 13px;
    font-family: "Monaco", monospace;
  }
  .left-row {
    display: flex;
    margin-right: 5%;
  }

  .since-row {
    margin-top: 50px;
    font-family: "Monaco", monospace;
    font-size: 12px;
  }
}

@media only screen and (max-width: 839px) {
  .footer-container {
    background-color: #000;
    color: #ffffff;
    padding: 20px;
    text-align: center;
  }
  .footer-row {
    display: grid;
    justify-content: center;
  }
  .hotel-name {
    font-size: 50px;
    font-family: "Monaco", monospace;
  }
  .hotel {
    font-weight: 200px;
    font-family: "Monaco", monospace;
  }
  .text-row {
    margin-bottom: 25px;
  }
  .text {
    font-size: 13px;
    font-family: "Monaco", monospace;
  }
  .left-row {
    display: flex;
    margin-left: 30%;
  }
  .since-row {
    margin-top: 30px;
    font-family: "Monaco", monospace;
    font-size: 12px;
  }
}
