.App {
  background-color: #ffffff;
}
.home-background {
  background-image: url("../../assets/background.png");
  background-size: contain;
  background-repeat: repeat-y;
}

body {
  background-color: #ffffff;
}

.flex {
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 1300px) {
  .home-image {
    width: 100%;
    height: 800px;
    z-index: -1;
    object-fit: cover;
  }
  .explanation-row {
    padding-top: 160px;
    padding-bottom: 160px;
  }
  .title {
    font-size: 28px;
    font-family: "Monaco", monospace;
    color: #e01616;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);
  }
  .hotel-explanation {
    color: #232323;
    text-align: center;
    margin-left: 420px;
    margin-right: 420px;
    line-height: 25px;
    letter-spacing: normal;
    font-size: 14px;
    font-family: "Monaco", monospace;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1028px) and (max-width: 1299px) {
  .home-image {
    width: 100%;
    height: 570px;
    margin-top: 100px;
    z-index: -1;
    object-fit: cover;
  }
  .explanation-row {
    padding-top: 120px;
    padding-bottom: 160px;
  }
  .title {
    font-size: 28px;
    font-family: "Monaco", monospace;
    color: #e01616;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);
  }
  .hotel-explanation {
    color: #232323;
    text-align: center;
    margin-left: 18%;
    margin-right: 18%;
    line-height: 25px;
    letter-spacing: normal;
    font-size: 14px;
    font-family: "Monaco", monospace;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 910px) and (max-width: 1027px) {
  .home-image {
    width: 100%;
    height: 520px;
    margin-top: 90px;
    z-index: -1;
    object-fit: cover;
  }
  .explanation-row {
    padding-top: 100px;
    padding-bottom: 130px;
  }
  .title {
    font-size: 23px;
    font-family: "Monaco", monospace;
    color: #e01616;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);
  }
  .hotel-explanation {
    color: #232323;
    text-align: center;
    margin-left: 18%;
    margin-right: 18%;
    line-height: 25px;
    letter-spacing: normal;
    font-size: 14px;
    font-family: "Monaco", monospace;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 520px) and (max-width: 909px) {
  .home-image {
    width: 100%;
    height: 470px;
    margin-top: 130px;
    z-index: -1;
    object-fit: cover;
  }
  .explanation-row {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .title {
    font-size: 22px;
    font-family: "Monaco", monospace;
    color: #e01616;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);
  }
  .hotel-explanation {
    color: #232323;
    text-align: center;
    margin-left: 18%;
    margin-right: 18%;
    line-height: 25px;
    letter-spacing: normal;
    font-size: 14px;
    font-family: "Monaco", monospace;
    margin-top: 20px;
  }
}
@media only screen and (max-width: 519px) {
  .home-image {
    width: 100%;
    height: 470px;
    margin-top: 100px;
    z-index: -1;
    object-fit: cover;
  }
  .explanation-row {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .title {
    font-size: 22px;
    font-family: "Monaco", monospace;
    color: #e01616;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    text-shadow: 1px 2px 5px rgba(87, 87, 87, 0.5);
  }
  .hotel-explanation {
    color: #232323;
    text-align: center;
    margin-left: 12%;
    margin-right: 12%;
    line-height: 18px;
    letter-spacing: normal;
    font-size: 12px;
    font-family: "Monaco", monospace;
    margin-top: 15px;
  }
}
